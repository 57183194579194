html {
  scroll-behavior: smooth;
}

body {
  font-family: Helvetica, sans-serif;
}

h1 {
  font-family: "League Spartan", sans-serif;
}
h2 {
  font-family: Helvetica, sans-serif;
  font-weight: 500;
  font-size: 2rem;
}
p {
  font-size: 1rem;
  font-weight: 100;
}
.textHighlight {
  color: #D60004;
}
.textHighlightblack {
  color: black;
}

/* --- Promo Message --- */
.promoMsg {
  background-color: #D60004;
}
.promoMsgTxt {
  color: #ffffff;
  font-size: 0.8rem;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 0;
}

/* --- Navbar --- */
.navbar-default {
  padding: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  position: absolute;
  width: 100%;
}
.navbar-black {
  background: black;
}
.desktopNavbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 10px;
  padding-bottom: 10px;
}
.nav-link {
  color: white;
  font-weight: 100;
  cursor: pointer;
  text-align: center;
}
.nav-link:hover {
  color: white;
  text-decoration: underline;
}
.navHalf1 {
  display: flex;
  justify-content: space-evenly;
  width: 35%;
}
.navLogo {
  width: 100px;
  height: auto;
}
.navHalf2 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 35%;
}
.scheduleBtn {
  background-color: #D60004;
  padding: 10px 30px;
  border-radius: 50px;
  border-style: none;
  text-decoration: none !important;
  transition: all 0.3s ease;
}
.scheduleBtn:hover {
  animation: shake 1.5s ease;
}


/* mobile navbar */
.mobileNavbar {
  background-color: black;
  width: 100%;

}
.mobileNavbarWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}

.barsIcon {
  width: 30px;
}
.mobileMenuBtns {
  background-color: rgba(2, 2, 2, 0);
  border-style: none;
  padding: 5px 5px;
}
.offcanvas {
  background-color: black;
}
.offcanvas-header {
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mobileNavItems {
  list-style-type: none;
  padding: 0;
}
.mobileNavItem {
  padding-top: 15px;
  padding-bottom: 15px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.3);
  border-top: none;
  border-left: none;
  border-right: none;
}
.mobileNavOffCanvasLogo {
  padding-top: 20px;
  width: 90px;
}

/* --- Home/Landing Page --- */
.homeSection {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/public/imgs/DTlanding.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.landingHeading {
  color: white;
  text-align: center;
  font-size: 3rem;
}
.landingText {
  width: 60%;
  color: white;
  text-align: center;
  font-size: 1.2rem;
}
.homeSectionContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.landingBtn {
  font-weight: 100;
  color: white;
  background-color: #D60004;
  padding: 10px 30px 10px 30px;
  border-radius: 50px;
  border-style: none;
  text-decoration: none !important;
  transition: all 0.3s ease;
}
.landingBtn:hover {
  animation: shake 1.5s ease;
}


/* --- Philosophy Section --- */
.philosophySection {
  padding-top: 20px;
  padding-bottom: 20px;
}
.philosophyContent {
  display: flex;
  align-items: center;
  gap: 1px;
}
.philosophyFlexItem {
  flex: 1 1 50%;
}
.philosophyHeading {
  text-align: center;
}


/* --- Programs Section --- */
.programsSection {
  background-color: black;
  padding-top: 25px;
  padding-bottom: 25px;
}
.programsHeading {
  color: white;
  text-align: center;
}
.programsList {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-top: 15px;
  padding-bottom: 15px;
}
.programItem {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.programsText {
  color: white;
  margin-bottom: 0;
  font-size: 1.3rem;
}
.bulletHole {
  width: 1.3rem;
  margin-bottom: 3px;
}


/* --- Class We Offer --- */
.offeredClassesSection {
  padding-top: 30px;
  padding-bottom: 30px;;
}
.offeredClassesHeading {
  padding-bottom: 10px;
}
.classCardswrapper {  
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
}
.cardItemWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cardItemBtn {
  width:90%;
}
.learnMoreBtn {
  width:100%;
  background-color: #D60004;
  border-radius: 50px;
  border-style: solid;
  border-width: 1px;
  border-color: #D60004;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
  color:#ffffff;
  text-decoration: none;
  font-weight: 100;
}
.learnMoreBtn:hover {
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: #D60004;
  color:#D60004;
}
.classCardItem {
  width: 300px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.classCardItem1 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/public/imgs/mdccw.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.classCardItem2 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/public/imgs/mjccw.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; 
}
.classCardItem3 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/public/imgs/pms.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.classCardItem4 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/public/imgs/gt.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.classCardHeading {
  color: white;
  font-weight: 100;
  font-size: 1.3rem;
  text-align: center;
  padding: 5px;
}
.modal-content {
  background-color: black;
}
.modal-header {
  color: white;
}
.modal-body {
  color: white;
}
.modalCloseBtn {
  width: 10px;
}


/* Booking Section */
.bookingSection {
  background-color: black;
  padding-top: 30px;
  padding-bottom: 30px;
}
.bookingSection-White {
  background-color: white;
}
.bookingContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.bookingHeading-default {
  color: white;
}
.bookingHeading-White {
  color: black;
}
.squareBookingBtn {
  background-color: #D60004;
  color: white;
  font-weight: 100;
  padding: 10px 30px;
  border-style: solid;
  border-width: 1px;
  border-radius: 50px;
  border-color: #D60004;
  text-decoration: none !important;
  transition: all 0.3s ease;
}
.squareBookingBtn:hover {
  animation: shake 1.5s ease;
}


/* --- Schedule Section --- */
.scheduleSection {
  background-color: black;
  padding-top: 25px;
  padding-bottom: 35px;
}
.scheduleWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.scheduleSectionHeading {
  color:#ffffff;
}
.stepHeading {
  color: white;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 100;
  padding-bottom: 5px;
}
.selectOptions {
  word-wrap: normal;
  font-family: Helvetica, sans-serif;
  background-color: black; 
  color: white;
  border-style: solid;
  border-width: 1px;
  border-color: white;
  border-radius: 50px;
  padding: 10px;
  margin-bottom: 8px;
}
.nextPrevBtn {
  background-color: black;
  color: white;
  border-style: solid;
  border-width: 1px;
  border-color: white;
  border-radius: 50px;
  padding: 5px 30px;
  font-size: 1rem;
  font-weight: 100;
  margin-top: 8px;
}
.nextPrevBtn:hover {
  background-color: #D60004;
}
.formInputBoxes {
  background-color: black;
  color: white;
  border-style: solid;
  border-width: 1px;
  border-color: white;
  border-radius: 50px;
  font-weight: 100;
  padding: 10px 40px;
  margin-left: 5px;
  margin-right: 5px;
}
.step-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stepInputRow {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.stepBtnsRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: 10px;
  padding-top: 10px;
}
.step-1-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
}
.step-1-div-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 5rem;
}
.step1Div1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.formFields {
  width: 350px;
}
.react-calendar {
  width: 350px;
  max-width: 100%;
  background: black !important;
  border: 1px white !important;
  font-family:  Helvetica, sans-serif !important;
  line-height: 1.125em;
  border-style: solid !important;
  border-width: 1px !important;
  padding: 20px;
  border-radius: 30px;
  margin-bottom: 8px;
}
.react-calendar, .react-calendar *, .react-calendar *:before, .react-calendar *:after {
  box-sizing: border-box;
  color: white;
}
.react-calendar__navigation button:disabled {
  background-color: black !important;
}
.react-calendar__navigation {
  margin-bottom: 0;
}
.react-calendar button {
  margin: 0;
  border-style: solid !important;
  border-width: 1px !important;
  outline: none !important;
  border-color: white !important;
  border-radius: 50px !important;
}
.react-calendar__tile--active {
  background: red !important;
}
.react-calendar__tile--now {
  background: #d6000450 !important;
}
.react-calendar__tile:hover {
  background: red !important;
}
.react-calendar__tile:disabled {
  background-color: rgb(161 161 161 / 50%) !important;
}
.formNameWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.smallFormNote {
  color: white;
  font-weight: 100;
  font-size: .6rem;
}
.bookingSummaryText {
  color:white;
}


/* --- About Us Section --- */
.aboutUseWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1px;
}
.aboutUsHeading {
  text-align: center;
}
.aboutUsText {
  padding-left: 40px;
  padding-right: 40px;
}
.aboutUsItem {
  width: 50%;
}
.aboutUsImg {
  background-image: url(/public/imgs/coachk.png);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  height: 400px;
}


/* --- Testimonials/Reviews --- */
.reviewsHeading {
  text-align: center;
  padding-top: 25px;
}
.reviewsWrapper {
  display: flex;
  gap: 1px;
  justify-content: center;
}
.reviewItem {
  width: 50%;
}
.reviewItem2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.reviewsCarouselImg {
  object-fit: cover;
  object-position: top;
  height: 400px;
}
.reviewsCardsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 90%;
}
.reviewsCard {
  flex: 1 1 calc(50% - 10px);
  border-style: solid;
  border-width: 1px;
  border-color: black;
  border-radius: 15px;
  width: 85%;
  padding: 10px;
}
.reviewsCardText {
  font-size: .8rem;
  margin-bottom: 0px;
}
.reviewsCardRating {
  display:flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
}
.fiveStartSvg {
  width: 4.5rem;
}


/* --- FAQs Section --- */
.faqsSection {
  background-color: black;
  padding-top: 25px;
  padding-bottom: 25px;
}
.faqsHeading {
  color: white;
  text-align: center;
  padding-bottom: 20px;;
}
.accordion {
  --bs-accordion-bg: black;
  --bs-accordion-color: white;
  --bs-accordion-border-color: rgba(255, 255, 255, 0.5);
  --bs-accordion-btn-color: white;
  --bs-accordion-btn-bg: black;
  --bs-accordion-active-color: red;
  --bs-accordion-active-bg: black;
  --bs-accordion-btn-focus-box-shadow: none;
}
.accordion-item {
  border-left: none;
  border-right: none;
  border-top: none;
}
.accordion-button {
  font-size: 1rem;
  font-weight: 100;
}
/* accordion arrow btn color */
.accordion-button::after {
  filter: invert(100%);
}
.accordion-button:not(.collapsed)::after {
  filter: invert(29%) sepia(81%) saturate(7033%) hue-rotate(358deg) brightness(104%) contrast(107%);
}
.accordion-body {
  font-size: 1rem;
  font-weight: 100;
}


/* --- Contact Section --- */
.contactWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between
}
.contactItem1 {
  width: 50%;
}
.contactItem2 {
  width: 50%;
}
.contactHeadingDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.contactSubHeading {
  font-size: 1.5rem;
}
.contactFormDiv {
  background-color: black;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
}
.contactFormHeading {
  font-size: 1.5rem;
  color: white;
  font-weight: 100;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 15px;
}
.mobileContactFormHeadingDiv {
  color: white;
  text-align: center;
}
.contactMobileSubHeading {
  font-size: 1rem;
  font-weight: 100;
  margin: 0;
}
.form-control {
  background-color: black;
  font-weight: 100;
  color:white;
}
input::placeholder {
  color: White !important;
  opacity: 1 !important;
}
.form-check-label {
  color: white;
}
.form-check-input:checked {
  background-color: red;
  border-color: red;
}
textarea::placeholder {
  color: white !important;
}
.smsText {
  color: white;
  font-weight: 100;
  font-size: 1rem;
}
.contactFormBtnDiv {
  display: flex;
  justify-content: center;
}
.contactFormSubmitBtn {
  background-color: #D60004;
  color: white;
  font-weight: 100;
  border-style: solid;
  border-color:#D60004;
  border-width: 1.5px;
  border-radius: 50px;
  padding: 10px 35px;
  margin-top: 15px;
}
.contactFormSubmitBtn:hover {
  background-color: white;
  border-color: #D60004;
  color:#D60004;
}


/* --- Footer Section --- */
.footerSection {
  background-color: black;
  padding-top: 25px;
  padding-bottom: 5px;
}
.footerWrapper {
  display: flex;
  justify-content: space-evenly;
}
.footerContentDivs {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.followUsLinks {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.followUsImg {
  width: 2rem;
}
.footerSubHeading {
  color: white;
  font-size: 1rem;
  font-weight: 400;
}
.footerLink {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 100;
}
.footerLink:hover {
  color:#D60004;
}
.copyRightDiv {
  padding-top: 20px;
}
.copyRightText {
  color: rgba(255, 255, 255, 0.65);
  text-decoration: none;
  font-size: .7rem;
}


/* --- --- Classes Page --- --- */
.classesPage {
  background-color: black;
}
.classesPs {
  display: flex;
  flex-direction: row;
}
.classesPsRev {
  display: flex;
  flex-direction: row-reverse;
}
.classesPsDiv {
  width: 50%;
}
.classPsDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}
.classesPsImg1 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/public/imgs/mdccw.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.classesPsImg2 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/public/imgs/mjccw.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; 
}
.classesPsImg3 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/public/imgs/pms.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; 
}
.classesPsImg4 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/public/imgs/gt.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; 
}
.classesPsText {
  padding: 20px 30px 10px 30px
}
.classesPsHeading {
  color: white;
  font-weight: 100;
  font-size: 2rem;
  text-align: center;
}
.classesPsPara {
  color: white;
  font-weight: 100;
  font-size: 1rem;
}
.scheduleClassesPs {
  padding-top: 20px;
  border-top: .5px solid rgba(255, 255, 255, 0.5);
}
.faqsClassesPs {
  border-top: .5px solid rgba(255, 255, 255, 0.5);
}
.footerClassesPs {
  border-top: .5px solid rgba(255, 255, 255, 0.5);

}
.classesPageContactDiv {
  background-color: white;
  padding-top: 30px;
  padding-bottom: 30px;
}
.classesPageContactDivContent {
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
  font-weight: 100;
}

/* About Page */
.aboutPage {
  background-color: black;
}
.aboutPageHeadDiv {
  background-color: white;
}
.aboutPageHeading {
  margin-bottom: 0px;
  color: black;
  padding-top: 20px;
  padding-bottom: 20px;
}
.aboutPageAboutParaDivDesktop {
  background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/public/imgs/rifleBullets.png);
}
.aboutPageAboutPara {
  color: white;
  padding-top: 30PX;
  padding-bottom: 30px;
  margin-bottom: 0px;
}
.aboutPageAboutParaDivMobile {
  background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/public/imgs/rifleBullets.png);
  padding-bottom: 15px;
}
.aboutPageReadMoreBtn {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: white;
}
.getToKnowWrapper {
  display: flex;
}
.getToKnowDiv {
  width: 50%;
}
.getToKnowHeading {
  color: black;
}
.getToKnowHeadingMobile {
  padding-top: 10px;
  padding-bottom: 10px;
}
.getToKnowDivText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.aboutPageProfileImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

/* Blogs Page */
.blogPage {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blogPageContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
.blogPageBackBtn {
  color: black;
  text-decoration: none;
  border: 1px solid black; 
  border-radius: 50px;
  padding: 5px 20px;
}
.blogPageBackBtn:hover {
  color:#D60004;
  border-color: #D60004;
}

/* --- btn shake animation --- */
@keyframes shake {
  0%, 100% { transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% { transform: translateX(-5px); }
  20%, 40%, 60%, 80% { transform: translateX(5px); }
}



/* --- ---  Media Queries --- --- */


/* Above iPad Air */
@media screen and (min-width: 821px) {
  /* navbar */
  .mobileNavbar {
    display: none;
  }
  .mobileNavbar.container {
    display: none;
  }
  /* about us */
  .aboutUsMobile {
    display: none;
  }
  /* contact section */
  .mobileContactFormHeadingDiv {
    display: none;
  }
  /* contact page */
  .classesPsHeadingMobile {
    display: none;
  }
  /* about page */
  .aboutPageAboutParaDivMobile {
    display: none;
  }
}

@media screen and (min-width: 821px) and (max-width: 1075px) {
    .reviewsSection {
      margin-top: 20px;
    }
    .reviewsHeading {
      padding-top: 0px;
    }
    .reviewItem1{
      width: 40%
    }
    .reviewItem2{
      width: 60%
    }
    /* about page */
    .aboutPageAboutParaDivMobile {
      display: none;
    }
 }

/* iPad Air and down */
@media screen and (max-width: 820px) {
  /* navbar */
  .desktopNavbar {
    display: none;
  }
  .navLogo {
    width: 60px;
  }
  /* philosophy section */
  .philosophyContent {
    flex-wrap: wrap-reverse;
  }
  /* about us section */
  .aboutUsDesktop {
    display: none;
  }
  .aboutUsMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .aboutUsHeading {
    color:#ffffff;
  }
  .aboutUsText {
    padding-left: 15px;
    padding-right: 15px;
  }
  .aboutUsImg {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/public/imgs/coachk.png);
    display: flex;
    align-items: start;
    margin: 0px;
    width: 100%;
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .aboutUsText {
    color:white;
    padding: 0px;
  }
  /* reviews section */
  .reviewsHeading {
    color:white;
  }
  .reviewsWrapper {
    flex-wrap: wrap-reverse;
    background-color: black;
    gap: 30px;
  }
  .reviewItem {
    width: 90%;
  }
  .reviewsCard {
    border-color: white;
  }
  .reviewsCardText {
    color:white;
  }
  /* reviews */
  .faqsSection {
    padding-top: 45px;
    padding-bottom: 40px;
  }
  /* contact section*/
  .contactHeadingDiv {
    display: none;
  }
  .contactFormDiv {
    padding-top: 30px;
    background-color: white;
  }
  .contactItem2 {
    width: 100%
  }
  .contactFormHeadingDiv {
    display: none;
  }
  .mobileContactFormHeadingDiv {
    color: black;
  }
  .form-control {
    background-color: white;
    font-weight: 100;
    color: black;
  }
  #contactFirstName::placeholder, 
  #contactLastName::placeholder,
  #InputEmail1::placeholder,
  #phoneNumber::placeholder,
  #subject::placeholder {
    color: black !important;
    opacity: 1 !important;
  }


  .form-check-label {
    color: black ;
  }
  .form-check-input:checked {
    background-color: red;
    border-color: red;
  }
  textarea::placeholder {
    color: black !important;
  }
  .form-control {
    border-color: black;
  }
  .smsText {
    color: black;
  }
}
@media screen and (min-width: 769px) and (max-width: 820px) {
  /* About page */
  .aboutPageAboutParaDivDesktop {
    display: none;
  }
}

/* iPad mini and down */
@media screen and (max-width: 768px) {
  /* landing section */
  .landingHeading {
    font-size: 2rem;
  }
  .landingText {
    width: 90%;
    font-size: 1rem;
  }
  /* classes we offer */
  .classCardItem {
    width: 340px;
  } 
  /* reviews */
  .reviewsCardsWrapper {
    flex-direction: column;
    width: 100%;
    align-content: center;
  }
  .reviewsCard {
    flex-basis: 0;
    margin: 0;
    width: 100%;
  }
  /* footer */
  .footerWrapper {
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }
  .copyRightText {
    text-align: center;
  }
  /* classes page */
  .classesPs {
    flex-direction: column;
  }
  .classesPsHeadingDesktop {
    display: none;
  }
  .classesPsDiv {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap:5px;
  }
  .classesPsRev {
    flex-direction: column;
  }
  /* about page */
  .aboutPageAboutParaDivDesktop {
    display: none;
  }
  .aboutPageHeading {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .aboutPageAboutPara {
      padding-bottom: 10px;
  }
  /* schedule section */
  .stepBtnsRow {
    width: 100%;
  }

}
@media screen and (min-width: 601px) {
  .getToKnowHeadingMobile {
    display: none;
  }
}

/* Larger phone screens and down*/
@media screen and (max-width: 600px) {

  /* Sub headings */
  h2 {
    font-size: 1.8rem;
  }
  /* schedule section */
  .formNameWrapper {
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px
  }
  /* about page */
  .getToKnowWrapper {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .getToKnowDiv {
    width: 100%;
  }
  .getToKnowHeadingDesktop {
    display: none;
  }
  .getToKnowDivImg {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .aboutPageProfileImg {
    width: 90%;
  }
  .getToKnowParaText {
    padding-top: 20px;
    padding-bottom: 10px
  }
}


@media screen and (min-width:351px) and (max-width: 450px) {
  .classCardItem {
    width: 350px;
  } 
  /* contact form */
  .contactItem2 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 350px) {
  .formFields {
    width: 310px;
  }
  .react-calendar {
    width: 310px !important;
  }
}
